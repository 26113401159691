
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





























































.organization-conversion-apis__google {
  --gradient-start: theme('colors.jb-indigo.darker');
  --gradient-end: theme('colors.jb-indigo.DEFAULT');

  background: linear-gradient(-45deg, var(--gradient-start) 24%, var(--gradient-end) 24%);
}
